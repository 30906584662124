import React from 'react'
import {graphql, Link} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'

import '../styles/contact.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ContactPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    about: sanitySiteAbout(_id: { regex: "/(drafts.|)siteContact/" }) {
      title
      description
      _rawBody
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }


  const about = (data || {}).about
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        <h1 className>{about.title}</h1>
        {about._rawBody && <PortableText blocks={about._rawBody} />}

        <div class="container center">
            <form action="/contact-thank-you" netlify>
                <label for="name">Full Name</label>
                <input type="text" id="name" name="name" placeholder="Your name.."></input>

                <label for="phone">Phone</label>
                <input type="phone" id="phone" name="phone"></input>

                <label for="email">Email</label>
                <input type="email" id="email" name="email"></input>

                <label for="subject">Subject</label>
                <textarea id="subject" name="subject" placeholder="Write something.."></textarea>

                <input type="submit" value="Submit"></input>
            </form>
        </div>
      </Container>
    </Layout>
  )
}

export default IndexPage
